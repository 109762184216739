<template>
  <div class="register">
    <div class="register-container">
      <div class="register-box">
        <div class="register-header">
          <h2>创建新账号</h2>
          <p>请填写以下信息完成注册</p>
        </div>
        <el-form
          ref="form"
          :rules="metaRules"
          :model="form"
          class="register-form"
          @submit.native.prevent
        >
          <el-form-item prop="nickname">
            <div class="input-wrapper">
              <i class="el-icon-user"></i>
              <el-input
                v-model="form.nickname"
                placeholder="请输入昵称"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="username">
            <div class="input-wrapper">
              <i class="el-icon-message"></i>
              <el-input
                v-model="form.username"
                placeholder="请输入用户名"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="password">
            <div class="input-wrapper">
              <i class="el-icon-lock"></i>
              <el-input
                v-model="form.password"
                type="password"
                show-password
                placeholder="请输入密码"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="confirmPassword">
            <div class="input-wrapper">
              <i class="el-icon-lock"></i>
              <el-input
                v-model="form.confirmPassword"
                type="password"
                show-password
                placeholder="请确认密码"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-button 
            class="submit-btn" 
            type="primary" 
            @click="login"
            :loading="loading"
          >
            注册
          </el-button>
        </el-form>
        <div class="register-footer">
          <el-link type="primary" @click="goToLogin" class="login-link">
            已有账号？立即登录
          </el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "register",
  data() {
    const validateConfirmPass = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback(); // 校验通过
      }
    };

    return {
      loading: false,
      form: {
        nickname: '',
        username: '',
        password: '',
        confirmPassword: '' // 确认密码字段需要加入 form 对象
      },
      metaRules: {
        nickname: [
          { required: true, message: '昵称不能为空', trigger: 'blur' },
          { min: 2, max: 12, message: '长度在2到12个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9_]{4,16}$/, message: '包含字母、数字或下划线（4-16位）', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: this.validatePassword, trigger: 'blur' } // 密码复杂度校验
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validateConfirmPass, trigger: 'blur' } // 自定义校验
        ]
      }
    };
  },
  methods: {
    validatePassword(rule, value, callback) {
      if (!/(?=.*[A-Za-z])(?=.*\d).{6,}/.test(value)) {
        callback(new Error('至少包含字母和数字，且长度≥6'));
      } else {
        callback();
      }
    },
    goToLogin(){
      this.$router.push('/login');
    },
    login() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          axios.post(this.$globle.allurl + "user/register", this.form).then(res => {
            this.loading = false;
            if (res.data === 1) {
              this.$message({
                type: "success",
                message: "注册成功"
              })
              this.$router.push({name: "login"})
            } else {
              this.$message({
                type: "error",
                message: "重复"
              })
            }
          })
          // 校验通过，执行提交逻辑
          console.log('提交数据:', this.form);
        } else {
          // 校验失败，给出提示
          this.$message.error('请检查表单填写是否正确');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.register {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.register-container {
  width: 100%;
  max-width: 420px;
  padding: 20px;
}

.register-box {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.register-header {
  text-align: center;
  margin-bottom: 40px;
}

.register-header h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 8px;
}

.register-header p {
  color: #666;
  font-size: 16px;
}

.register-form {
  margin-bottom: 24px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  border-radius: 8px;
  padding: 0 16px;
  transition: all 0.3s ease;
}

.input-wrapper:focus-within {
  background: #fff;
  box-shadow: 0 0 0 2px #409EFF;
}

.input-wrapper i {
  color: #909399;
  margin-right: 12px;
}

.input-wrapper :deep(.el-input__inner) {
  height: 48px;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #333;
  outline: none;
}

.input-wrapper :deep(.el-input__inner::placeholder) {
  color: #909399;
}

.submit-btn {
  width: 100%;
  height: 48px;
  background: #409EFF;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background: #66b1ff;
  transform: translateY(-1px);
}

.submit-btn:active {
  transform: translateY(0);
}

.register-footer {
  margin-top: 24px;
  text-align: center;
}

.login-link {
  font-size: 14px;
  color: #409EFF;
  text-decoration: none;
  transition: all 0.3s ease;
}

.login-link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>
